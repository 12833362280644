<template>
    <v-input
        v-if="isVisible"
        :id="name"
        :error="error"
        :error-messages="aggregatedErrors"
        :name="name"
        :class="['row-localized', { required: isRequired }]"
    >
        <l-multi-lang
            :available-locales="availableLocales"
            :current-locale="currentLocale"
            @tab-activated="e => focusInput(e)"
        >
            <template #default="{ locale: slotLocale, localeKey }">
                <v-text-field
                    v-if="childType === 'text'"
                    :id="name + '-' + localeKey"
                    :ref="'input_' + localeKey"
                    :model-value="viewValue[localeKey]"
                    :disabled="isDisabled"
                    :label="theLabel"
                    :lang="slotLocale"
                    :name="name + '[' + localeKey + ']'"
                    :required="isRequired"
                    :rules="myRules"
                    autocomplete="off"
                    type="text"
                    @update:model-value="value => setValue(localeKey, value)"
                >
                    <template #append>
                        <v-icon
                            :title="t('lumui.form.localized.copy_to_all')"
                            @click="setAllToCurrent"
                        >
                            $setAllToCurrent
                        </v-icon>
                    </template>
                </v-text-field>
                <v-textarea
                    v-else-if="childType === 'textarea'"
                    :id="name + '-' + localeKey"
                    :ref="'input_' + localeKey"
                    :model-value="viewValue[localeKey]"
                    :disabled="isDisabled"
                    :label="theLabel"
                    :lang="locale"
                    :name="name + '[' + localeKey + ']'"
                    :required="isRequired"
                    :rules="myRules"
                    auto-grow
                    autocomplete="off"
                    hide-details
                    type="text"
                    @update:model-value="value => setValue(localeKey, value)"
                >
                    <template #append>
                        <v-icon
                            :title="t('lumui.form.localized.copy_to_all')"
                            @click="setAllToCurrent"
                        >
                            $setAllToCurrent
                        </v-icon>
                    </template>
                </v-textarea>
                <redactor
                    v-else-if="childType === 'wysiwyg'"
                    :id="name + '-' + localeKey"
                    :ref="'input_' + localeKey"
                    :model-value="viewValue[localeKey]"
                    :config="config.redactor"
                    :disabled="disabled"
                    :lang="locale"
                    :name="name + '[' + localeKey + ']'"
                    :required="isRequired"
                    :rules="rules"
                    filled
                    hide-details
                    @update:model-value="value => setValue(localeKey, value)"
                />
            </template>
        </l-multi-lang>
    </v-input>
</template>

<script lang="ts">
import { VIcon, VInput, VTextarea, VTextField } from "vuetify/components";
import LMultiLang from "./LMultiLang.vue";
import Redactor from "./LFormRowRichText.vue";
import { useI18n } from "vue-i18n";
import { defineComponent } from "vue";
import { makeDefaultProps, useDefaults } from "../composables/DefaultProps";
import type { FormConfigLocalized } from "../config/Form";
import { formConfigLocalizedSchema } from "../config/Form.zod";
import { nonReactiveClone } from "../lib/nonReactiveClone";
import { useTranslationMapping } from "../composables/TranslationMapping";

// @ todo validation
/**
 * Selects the element that will be rendered based on the `type` value of the `config` object.
 *
 * ## Config
 *
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      |            | field type  |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | class                  | `String`                   | no       | `null`     | css class for custom styling |
 * | languages              | `Array`                    | (yes)    |  store.currentLocales | which languages to render |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`    | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`    | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`    | field is rendered.  |
 * | child                  | `"text"`, `"textarea"`, `"wysiwyg"` | no      | `"text"`  | type of the child elements. |
 * | redactor               | `Object`                   | no       | `{}`       | config for redactor |
 */

export default defineComponent({
    components: {
        LMultiLang,
        Redactor,
        VInput,
        VTextarea,
        VTextField,
        VIcon,
    },
    props: {
        /** value, only `object`s work as expected */
        modelValue: {
            type: Object,
            default: undefined,
        },
        ...makeDefaultProps<FormConfigLocalized>(formConfigLocalizedSchema),
    },
    emits: ["update:modelValue", "update:config"],
    setup(props, { emit }) {
        const { t, te, locale } = useI18n();
        const translationMapping = useTranslationMapping();
        return {
            t,
            te,
            locale,
            translationMapping,
            ...useDefaults(props, emit),
        };
    },
    data() {
        return {
            focusedLocale: null as null | string,
        };
    },
    computed: {
        childType() {
            return this.config.child || "text";
        },
        emptyValue() {
            const ret = {};
            for (const l of this.localeKeys) {
                ret[l] = "";
            }
            return ret;
        },
        overriddenLocales() {
            return (
                typeof this.config.languages !== "undefined" &&
                Array.isArray(this.config.languages) &&
                this.config.languages.length > 0
            );
        },
        availableLocales(): ReadonlyArray<string> {
            if (this.overriddenLocales) {
                const languages = this.config.languages ?? [];
                return languages.map(locale => {
                    const backendLocale = this.translationMapping.lookupLocale(locale);
                    if (!backendLocale) {
                        throw Error("could not map locale " + locale);
                    }
                    return backendLocale;
                });
            } else {
                return this.translationMapping.locales;
            }
        },
        localeKeys() {
            return this.availableLocales.map(this.translationMapping.mapLocale);
        },
        currentLocale() {
            if (this.availableLocales.includes(this.locale)) {
                const k = this.translationMapping.mapLocale(this.locale);
                if (!k) {
                    throw Error("could not lookup locale " + this.locale);
                }
            }
            return this.localeKeys[0];
        },
        // Die rules werden auf jedes input Feld angewandt
        myRules() {
            if (this.rules) {
                return this.rules;
            }
            const required = this.isRequired;
            return [v => !required || !!v || this.t("lumui.form.row.required")];
        },
        viewValue: {
            get(): Record<string, string> | object {
                return { ...this.emptyValue, ...this.modelValue };
            },
            set(v: Record<string, string> | object): void {
                this.$emit("update:modelValue", v);
            },
        },
    },
    mounted() {
        this.focusedLocale = this.locale;
    },
    methods: {
        getInput(localeKey: string): InstanceType<typeof VTextField> | undefined {
            return (
                (this.$refs["input_" + localeKey] as InstanceType<typeof VTextField>) ?? undefined
            );
        },
        /** set all locales to the value of the current */
        setAllToCurrent() {
            if (!this.focusedLocale) {
                return;
            }
            const value = this.viewValue[this.translationMapping.mapLocale(this.focusedLocale)];
            const values = nonReactiveClone(this.viewValue);
            this.localeKeys.forEach((localeKey: string) => {
                //this.$set(this.viewValue, locale, value);
                values[localeKey] = value;
            });
            this.viewValue = values;
        },
        /* @private */
        focusInput(e: { locale: string }) {
            this.focusedLocale = e.locale || this.availableLocales[0];
        },
        setValue(key: string, value: string) {
            this.viewValue = { ...this.viewValue, [key]: value };
        },
    },
});
</script>

<style scoped>
div {
    width: 100%;
}
</style>

<style>
.row-localized > .v-input__control > .v-input__slot {
    flex-direction: column;
}

.row-localized > .v-input__control > .v-input__slot > label {
    width: 100%;
}
</style>
