<template>
    <v-menu v-if="isVisible" :disabled="isDisabled">
        <template #activator="{ props: menuProps }">
            <v-text-field
                v-if="isVisible"
                :id="id"
                ref="picker"
                :class="{ required: isRequired }"
                :model-value="stringValue"
                :append-inner-icon="!isRequired ? ('$clear' as any) : undefined"
                :disabled="isDisabled"
                :label="theLabel"
                :name="name"
                :rules="myRules"
                :messages="[t('lumui.form.date.format_hint', { format: inputFormat })]"
                class="form-row-date"
                autocomplete="off"
                prepend-inner-icon="$calendar"
                v-bind="menuProps"
                @click:append-inner.stop="!isRequired ? clear() : undefined"
                @update:model-value="v => setStringDate(v)"
            />
        </template>
        <template #default>
            <v-date-picker
                :min="min?.format('YYYY-MM-DD')"
                :max="max?.format('YYYY-MM-DD')"
                show-week
                hide-title
                :disabled="isDisabled"
                :model-value="dateValue"
                :close-on-back="true"
                :close-on-content-click="false"
                @update:model-value="v => setDate(v)"
            >
            </v-date-picker>
        </template>
    </v-menu>
</template>

<script lang="ts">
import { makeDefaultProps, useDefaults } from "../composables/DefaultProps.js";
import { type FormConfigDate } from "../config/Form";
import { defineComponent } from "vue";
import { VDatePicker, VTextField, VMenu } from "vuetify/components";
import moment from "moment";
import { formConfigDateSchema } from "../config/Form.zod";
import { useI18n } from "vue-i18n";

/**
 * Date element
 *
 * #### Config
 *
 * | key                    | type                       | required | default    | description |
 * |------------------------|----------------------------|----------|------------|-------------|
 * | type                   | `String`                   | yes      | -          | always `"date"` |
 * | label                  | `String`, `false`          | no       | `false`    | fields label |
 * | required               | `Boolean`, `eval(String)`  | no       | `false`      | field is required.|
 * | disabled               | `Boolean`, `eval(String)`  | no       | `false`      | field is disabled.|
 * | visible                | `Boolean`, `eval(String)`  | no       | `false`      | field is rendered.  |
 * | min                    | `String`                   | no       | `undefined`  | Minimum date allowed. Format 'YYYY-MM-DD' |
 * | max                    | `String`                   | no       | `undefined`  | Maximum date allowed. Format 'YYYY-MM-DD' |
 */
export default defineComponent({
    components: {
        VTextField,
        VDatePicker,
        VMenu,
    },
    props: {
        /** YYYY-MM-DD */
        modelValue: {
            type: String,
            required: false,
            default: undefined,
            validator(v: string) {
                if (v == "") {
                    return true;
                }
                const parts = v.split(" ");
                const res = moment(parts[0], "YYYY-MM-DD", true).isValid();
                if (!res) {
                    console.error("LFormRowDate: value must be string formatted as YYYY-MM-DD");
                }
                return res;
            },
        },
        ...makeDefaultProps<FormConfigDate>(formConfigDateSchema),
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const { t, locale } = useI18n();
        return {
            t,
            locale,
            ...useDefaults(props, emit),
        };
    },
    data() {
        return {
            stringValue: "",
            dateValue: undefined as Date | undefined,
        };
    },
    computed: {
        inputFormat() {
            // make sure we have a dependency on locale#
            return moment.localeData(this.locale).longDateFormat("L");
        },
        myRules(): Array<(v: string) => true | string> {
            if (this.rules) {
                return this.rules;
            }
            const required = this.isRequired;
            const min = this.min;
            const max = this.max;
            return [
                (v: string) => !required || v != "" || this.t("lumui.form.row.required"),
                (v: string) =>
                    v == "" || // empty string is handled by required
                    moment(v, this.inputFormat, true).isValid() ||
                    this.t("lumui.form.row.invalid_date", { format: this.inputFormat }),
                (v: string) =>
                    v == "" || // don't validate empty values
                    !min || // min is not set, so no need to validate
                    moment(v, this.inputFormat, true).isSameOrAfter(min) ||
                    this.t("lumui.form.row.min", { min: min.format(this.inputFormat) }),
                (v: string) =>
                    v == "" || // don't validate empty values
                    !max || // max is not set, so don't validate
                    moment(v, this.inputFormat, true).isSameOrBefore(max) ||
                    this.t("lumui.form.row.max", { max: max.format(this.inputFormat) }),
            ];
        },

        min() {
            if (!this.config.min) {
                return undefined;
            }
            const d = moment(this.config.min, "YYYY-MM-DD", true);
            return d.isValid() ? d : undefined;
        },
        max() {
            if (!this.config.max) {
                return undefined;
            }
            const d = moment(this.config.max, "YYYY-MM-DD", true);
            return d.isValid() ? d : undefined;
        },
    },
    watch: {
        inputFormat(newFormat) {
            if (this.dateValue) {
                this.stringValue = moment(this.dateValue).format(newFormat);
            }
        },
        modelValue(v: string) {
            if (v == "") {
                this.stringValue = "";
                this.dateValue = undefined;
            } else {
                const d = moment(v, "YYYY-MM-DD", true);
                const d2 = moment(v, "YYYY-MM-DD HH:mm:ss", true)
                if (!d.isValid() && !d2.isValid()) {
                    this.clear();
                    return;
                }
                let iso: string;
                if (d.isValid()) {
                    this.dateValue = d.toDate();
                    this.stringValue = d.format(this.inputFormat);
                    iso = d.format("YYYY-MM-DD");
                } else {
                    this.dateValue = d2.toDate();
                    this.stringValue = d2.format(this.inputFormat);
                    iso = d2.format("YYYY-MM-DD");
                }

                if (iso != v) {
                    this.$emit("update:modelValue", iso);
                }
            }
        },
    },
    mounted() {},
    methods: {
        clear() {
            this.$emit("update:modelValue", "");
        },
        setStringDate(v: string) {
            /* wir versuchen den string zu parsen. Wir schicken erst das update:modelValue raus, wenn wir
             * einen gültigen input haben. So kann der user die eingabe bearbeiten, ohne das sein input ständig
             * geändert wird.
             * Es gibt mit geringer Wahrscheinlichkeit eine Race-Condition, wenn der benutzer die Eingabe löscht
             * und dann sehr schnell weiter Eingaben macht.
             */
            v = v.replaceAll(/^\s*|\s*$/g, ""); // trim
            this.stringValue = v;
            if (v.match(/^\s*$/)) {
                this.clear();
                return;
            }
            const d = moment(v, this.inputFormat, true);
            if (d.isValid()) {
                this.$emit("update:modelValue", d.format("YYYY-MM-DD"));
            }
        },
        setDate(v: Date | undefined) {
            const d = moment(v);
            if (!d.isValid()) {
                this.clear();
            }

            this.dateValue = v;
            this.stringValue = v ? d.format(this.inputFormat) : "";
            this.$emit("update:modelValue", d.format("YYYY-MM-DD"));
        },
    },
});
</script>

<style scoped>
.form-row-date ::-webkit-calendar-picker-indicator {
    display: none !important;
}
</style>
